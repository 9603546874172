import React from 'react'
import type { FC } from 'react'
import { Helmet } from 'react-helmet'
import type { PageProps } from 'gatsby'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { useSiteLinksSearchBoxJsonLd } from 'src/views/home/Seo/useSiteLinksSearchBoxJsonLd'
import type { ColecaoNudesQueryQuery } from 'src/pages/colecao-nudes/__generated__/ColecaoNudesQuery.graphql'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'

type Props = PageProps<ColecaoNudesQueryQuery>

const Seo: FC<Props> = (props) => {
  const siteMetadata =
    props.data.cmsInstitutionalPage?.seo?.siteMetadataWithSlug

  const canonicalPathname = `https://www.avon.com.br/${siteMetadata?.slug}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')

  const siteLinksSearchBox = useSiteLinksSearchBoxJsonLd(props)

  const { isRepresentativeSelected } = useRepresentative()

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalPathname} />
      </Helmet>
      <GatsbySeo
        title={siteMetadata?.title ?? 'Nova coleção nudes Avon!'}
        description={
          siteMetadata?.description ??
          'Cores quentes para deixar o seu inverno cheio de estilo!'
        }
        titleTemplate={siteMetadata?.title ?? '%s | Avon'}
        noindex={!!isRepresentativeSelected}
      />
      <JsonLd json={siteLinksSearchBox} defer />
    </>
  )
}

export default Seo
