import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { Box } from '@vtex/store-ui'
import EventPageView from 'src/components/common/EventPageView'
import PageBanner from 'src/components/common/PageBanner'
import BeforeAndAfterBanners from 'src/components/colecao-nudes/BeforeAndAfterBanners'
import type { ColecaoNudesQueryQuery } from 'src/pages/colecao-nudes/__generated__/ColecaoNudesQuery.graphql'

type Props = PageProps<ColecaoNudesQueryQuery>

const Above: FC<Props> = ({ data }) => {
  return (
    <Box sx={{ background: '#1A2433' }}>
      <EventPageView
        type="OTHER"
        title={data.cmsInstitutionalPage.seo.siteMetadataWithSlug.title}
      />
      <PageBanner
        {...data.cmsInstitutionalPage?.sections[0].props}
        heightInline={['486px', 'auto']}
        heightBanner={{ height: 550, width: 1920 }}
        plLandingPage
        sx={{ paddingBottom: '0' }}
      />
      <BeforeAndAfterBanners data={data} />
    </Box>
  )
}

export default Above
