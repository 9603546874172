import React, { useState } from 'react'
import {
  Box,
  Container,
  Flex,
  Text,
  Image,
  Heading,
  SuspenseDevice,
} from '@vtex/store-ui'
import Modal from 'react-modal'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import styles from './styles.json'

const BeforeAndAfterBanners = ({ data }) => {
  const [selectedImage, setSelectedImage] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { isMobile } = useDeviceDetect()

  return (
    <Box>
      <Container sx={styles.container}>
        <Modal
          isOpen={isModalOpen}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 20,
            },
            content: {
              zIndex: 20,
              padding: 0,
              width: isMobile ? '90vw' : '60vw',
              height: 'auto',
              minHeight: 'fit-content',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              background: 'transparent',
              border: 'none',
              display: 'grid',
              placeItems: 'center',
              position: 'relative',
            },
          }}
        >
          <Image
            src={selectedImage ?? ''}
            sx={{
              width: ['80vw', '60vw'],
              maxWidth: '700px',
              height: '100%',
              maxHeight: ['80vh', 'unset'],
              cursor: 'pointer',
              objectFit: 'contain',
            }}
            onClick={() => setIsModalOpen(!isModalOpen)}
          />
          <Text
            as="span"
            onClick={() => setIsModalOpen(!isModalOpen)}
            sx={{
              display: 'grid',
              placeItems: 'center',
              borderRadius: '50%',
              height: ['1.5rem', '2.5rem'],
              width: ['1.5rem', '2.5rem'],
              background: '#F7D29D',
              color: '#1A2433',
              fontSize: ['1rem', '1.75rem'],
              lineHeight: '0',
              fontWeight: '800',
              position: 'absolute',
              right: ['3%', '1%'],
              top: '5%',
              cursor: 'pointer',
            }}
          >
            X
          </Text>
        </Modal>
        <Box sx={styles.centered}>
          <Heading sx={styles.title}>
            <Text as="strong">Nude Natural</Text>
            <Text as="span"> ou </Text>
            <Text as="strong">Nude Uau</Text>?
          </Heading>

          <Box sx={{ display: ['unset', 'none'] }}>
            <Flex sx={styles.flexContainer}>
              <Image
                sx={styles.flexContainer.image}
                alt={data?.cmsInstitutionalPage?.sections[1].props.alt}
                src={
                  data?.cmsInstitutionalPage?.sections[1].props.desktop.srcSet
                }
                onClick={() => {
                  setSelectedImage(
                    data?.cmsInstitutionalPage?.sections[1].props.mobile.srcSet
                  )
                  setIsModalOpen(true)
                }}
              />
            </Flex>
            <Box>
              <Image
                sx={styles.flexContainer.image}
                alt={data?.cmsInstitutionalPage?.sections[2].props.alt}
                src={
                  data?.cmsInstitutionalPage?.sections[2].props.desktop.srcSet
                }
                onClick={() => {
                  setSelectedImage(
                    data?.cmsInstitutionalPage?.sections[2].props.mobile.srcSet
                  )
                  setIsModalOpen(true)
                }}
              />
              <Image
                alt={data?.cmsInstitutionalPage?.sections[3].props.alt}
                sx={styles.flexContainer.image}
                src={
                  data?.cmsInstitutionalPage?.sections[3].props.desktop.srcSet
                }
                onClick={() => {
                  setSelectedImage(
                    data?.cmsInstitutionalPage?.sections[3].props.mobile.srcSet
                  )
                  setIsModalOpen(true)
                }}
              />
            </Box>
            <Box>
              <Image
                alt={data?.cmsInstitutionalPage?.sections[4].props.alt}
                sx={styles.flexContainer.image}
                src={
                  data?.cmsInstitutionalPage?.sections[4].props.desktop.srcSet
                }
                onClick={() => {
                  setSelectedImage(
                    data?.cmsInstitutionalPage?.sections[4].props.mobile.srcSet
                  )
                  setIsModalOpen(true)
                }}
              />
              <Image
                alt={data?.cmsInstitutionalPage?.sections[5].props.alt}
                sx={styles.flexContainer.image}
                src={
                  data?.cmsInstitutionalPage?.sections[5].props.desktop.srcSet
                }
                onClick={() => {
                  setSelectedImage(
                    data?.cmsInstitutionalPage?.sections[5].props.mobile.srcSet
                  )
                  setIsModalOpen(true)
                }}
              />
            </Box>
          </Box>

          <SuspenseDevice device="desktop" fallback={null}>
            <Flex sx={styles.flexContainer}>
              <Image
                alt={data?.cmsInstitutionalPage?.sections[1].props.alt}
                sx={styles.flexContainer.image}
                src={
                  data?.cmsInstitutionalPage?.sections[1].props.desktop.srcSet
                }
                onClick={() => {
                  setSelectedImage(
                    data?.cmsInstitutionalPage?.sections[1].props.desktop.srcSet
                  )
                  setIsModalOpen(true)
                }}
              />
              <Image
                alt={data?.cmsInstitutionalPage?.sections[2].props.alt}
                sx={styles.flexContainer.image}
                src={
                  data?.cmsInstitutionalPage?.sections[2].props.desktop.srcSet
                }
                onClick={() => {
                  setSelectedImage(
                    data?.cmsInstitutionalPage?.sections[2].props.desktop.srcSet
                  )
                  setIsModalOpen(true)
                }}
              />
            </Flex>
            <Flex sx={styles.flexCentered}>
              <Image
                alt={data?.cmsInstitutionalPage?.sections[3].props.alt}
                sx={styles.flexContainer.image}
                src={
                  data?.cmsInstitutionalPage?.sections[3].props.desktop.srcSet
                }
                onClick={() => {
                  setSelectedImage(
                    data?.cmsInstitutionalPage?.sections[3].props.desktop.srcSet
                  )
                  setIsModalOpen(true)
                }}
              />
              <Image
                alt={data?.cmsInstitutionalPage?.sections[4].props.alt}
                sx={styles.flexContainer.image}
                src={
                  data?.cmsInstitutionalPage?.sections[4].props.desktop.srcSet
                }
                onClick={() => {
                  setSelectedImage(
                    data?.cmsInstitutionalPage?.sections[4].props.desktop.srcSet
                  )
                  setIsModalOpen(true)
                }}
              />
              <Image
                alt={data?.cmsInstitutionalPage?.sections[5].props.alt}
                sx={styles.flexContainer.image}
                src={
                  data?.cmsInstitutionalPage?.sections[5].props.desktop.srcSet
                }
                onClick={() => {
                  setSelectedImage(
                    data?.cmsInstitutionalPage?.sections[5].props.desktop.srcSet
                  )
                  setIsModalOpen(true)
                }}
              />
            </Flex>
          </SuspenseDevice>
        </Box>
      </Container>
    </Box>
  )
}

export default BeforeAndAfterBanners
